import React from "react"
import { Box } from 'rebass'
import content from "../../content/i18n/en"
import Layout from "../components/layout"
import { gridUnit } from '../theme'
import { StyledH2, StyledP } from '../components/styles'


const About = () =>
  <Layout>
    <Box
      as='main'
      mx={gridUnit(5)}
      mb={4}
      mt={4}
      maxWidth="600px"
      minWidth="300px"
    >
      <StyledH2
        mb={1}
        mt={3}
      >
        { content.pages.about.title }
      </StyledH2>
      <main>
        { content.pages.about.introduction.map(paragraph => (
          <StyledP
            mb={2}
          >
            {paragraph}
          </StyledP>
        ))}
      </main>
    </Box>
  </Layout>

export default About
